@import "assets/scss/helpers/variables";

.gray-box {
	background-color: $color-tuna;
	border-radius: 0.25rem;
	margin-bottom: 1.875rem;
	overflow: hidden;
	padding: 2.75rem;
	position: relative;
	&--striped {
		background-image: url(/assets/images/gray-box-lined-bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		padding: 3.75rem; 
	}
}
