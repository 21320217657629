@import "assets/scss/helpers/variables";

.mjf {
	&__helper-text {
		display: flex;
		font-family: $font-family-gotham-book;
		font-size: 0.625rem;
		justify-content: flex-end;
		margin-top: 0.5rem;
	}
	&__progress {
		display: flex;
		font-family: $font-family-gotham-book;
		font-size: 0.8rem;
		align-items: center;
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
		&__body {
			flex-grow: 1;
		}
	}
	&__progress-bar {
		&.progress {
			background-color: $color-tuna;
			height: 0.25rem;
			.progress-bar {
				background-color: $light;
				&[aria-valuenow="100"] {
					background-color: $primary;
				}
			}
		}
	}
}
