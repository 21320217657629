@import 'assets/scss/helpers/functions';
@import 'assets/scss/helpers/variables';

.hero {
    position: relative;
}

.hero__background-img {
    width: 100%;
}

.hero__overlay {
    width: 100%;
}

.hero__overlay--description {
    top: 188px;
}

.hero__description {
    width: 100%;
    margin-bottom: rem(51);
}

.hero__text {
    font-family: $font-family-gotham-extra-light;
    font-size: rem(16);
    @include media-breakpoint-up(sm) {
        font-size: rem(15);
    }
}

.hero__text strong {
    font-family: $font-family-gotham-bold;
}

.hero__text:nth-child(1) {
    margin-bottom: rem(22);
}

.hero__text:nth-child(2) {
    margin-bottom: rem(26);
}

@include media-breakpoint-down(lg) {

    .hero__description {
        width: 100%;
        margin-bottom: rem(51);
    }

}

/* CUSTOM BREAKPOINT */
@media (max-width: 1049px) {

    .hero__overlay--description {
        position: static;
    }

    .hero__description {
        width: 100%;
        margin-bottom: rem(51);
    }

}
