@import 'assets/scss/helpers/functions';
@import 'assets/scss/helpers/variables';

.services-list-item {
    padding: rem(47) rem(69);
    border-radius: 0.3125rem;
    background-color: $color-tuna;
    margin-bottom: rem(36);
}

.services-list-item:last-child:last-child {
    margin-bottom: 0;
}

.services-list-item__overline {
    margin-bottom: 0;
    font-family: $font-family-gotham-book;
    font-size: rem(16);
    line-height: 1.5;
}

.services-list-item__title {
    margin-bottom: 0;
    font-family: $font-family-gotham-black;
    font-size: 1.375rem;
    line-height: 1.5;
    color: $color-bright-turquoise;
}

.services-list-item__subtitle {
    margin-bottom: 0;
    font-family: $font-family-gotham-black;
    font-size: 1rem;
    line-height: 1.5;
    color: $color-bright-turquoise;
}

.services-list-item__content {
    padding-top: 10px;
}

.services-list-item__content a {
    margin-top: 15px;
}

.services-list-item__capabilities-list-item {
    font-family: $font-family-gotham-book;
    font-size: rem(16);
    line-height: 1.5;
}

.services-list-item__apply-btn {
    align-self: flex-end;
}

.services-list-item__level {
    color: #01e2da;
}