@font-face {
    font-family: 'Gotham Black';
    src: url('../../fonts/Gotham-Black.woff2') format('woff2'),
        url('../../fonts/Gotham-Black.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('../../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../../fonts/Gotham-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Book';
    src: url('../../fonts/Gotham-Book.woff2') format('woff2'),
        url('../../fonts/Gotham-Book.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Extra Light';
    src: url('../../fonts/Gotham-ExtraLight.woff2') format('woff2'),
        url('../../fonts/Gotham-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('../../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../../fonts/Gotham-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Light';
    src: url('../../fonts/Gotham-Light.woff2') format('woff2'),
        url('../../fonts/Gotham-Light.woff') format('woff');
}


@font-face {
    font-family: 'Gotham Ultra';
    src: url('../../fonts/Gotham-Ultra.woff') format('woff2'),
        url('../../fonts/Gotham-Ultra.woff') format('woff');
}