@import 'assets/scss/helpers/functions';
@import 'assets/scss/helpers/variables';

.steps {
    display: flex;
    align-items: center;
    padding-top: rem(38);
}

.steps__box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: rem(44);
    height: rem(44);
    border: 0.125rem solid $color-white;
    border-radius: 0.625rem;
}

.steps__title {
    position: absolute;
    top: rem(-40);
    left: 0;
    font-size: rem(16);
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
}

.steps__number {
    font-size: rem(25);
    font-weight: bold;
    line-height: 1;
}

.steps__divider {
    width: rem(87);
    margin: 0 rem(22);
    border-top: 0.125rem solid $color-white;
}

.steps__box--current {
    border: 0.125rem solid $color-vivid-tangerine;
    background-color: $color-vivid-tangerine;
}

.steps__box--current .steps__title {
    color: $color-vivid-tangerine;
}

.steps__box--previous {
    border: 0.125rem solid $color-bright-turquoise;
    background-color: $color-bright-turquoise;
}

.steps__box--previous .steps__title {
    color: $color-bright-turquoise;
}
