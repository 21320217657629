@import "assets/scss/helpers/variables";

.text-glow {
	color: $secondary;
	font-family: $font-family-gotham-ultra;
	padding: $h1-font-size 0;
	position: relative;
	text-transform: uppercase;
	&__shadow {
		display: flex;
        opacity: 0.6;
		overflow: hidden;
		position: absolute;

		-webkit-text-fill-color: transparent;
		-webkit-text-stroke-width: 1.5px;
		-webkit-text-stroke-color: $white;

		&--1 {
			align-items: flex-start;
			height: $h1-font-size * 0.5;
			top: 0;
		}
		&--2 {
			align-items: flex-start;
			height: $h1-font-size * 0.65;
			top: $h1-font-size * 0.45;
		}
		&--3 {
			align-items: flex-end;
			bottom: $h1-font-size * 0.4;
			height: $h1-font-size * 0.8;
		}
		&--4 {
			align-items: flex-end;
			bottom: 0;
			height: $h1-font-size * 0.6;
		}
	}
}
