@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Color system
// Refer this url for color name suggestions https://chir.ag/projects/name-that-color

$color-white: #fff;
$color-bright-turquoise: #01e2da;
$color-scarpa-flow: #565669;
$color-shark: #212129;
$color-tuna: #3d3d4b;
$color-vivid-tangerine: #ffa074;
$color-waterloo: #84849c;
$color-error: #ff0033;
$primary: $color-bright-turquoise;
$secondary: $color-vivid-tangerine;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1110px,
);

$container-max-width-sm: map-get($container-max-widths, 'sm');

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-gotham-black: "Gotham Black";
$font-family-gotham-bold: "Gotham Bold";
$font-family-gotham-book: "Gotham Book";
$font-family-gotham-extra-light: "Gotham Extra Light";
$font-family-gotham-medium: "Gotham Medium";
$font-family-gotham-light: "Gotham Light";
$font-family-gotham-ultra: "Gotham Ultra";
$font-family-base: $font-family-gotham-book;
// stylelint-disable value-keyword-case

$font-size-root: 16px;

$h1-font-size: $font-size-base * 5;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.36;

$headings-font-family: $font-family-gotham-bold;

$input-btn-focus-color: rgba($primary, 0.25);

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 0.25rem;
$btn-padding-x: 2.375rem;
$btn-font-family: $font-family-gotham-medium;
$btn-font-size: 1rem;

$btn-border-width: 0.25rem;

$btn-border-radius: 0.625rem;

// Navs
$nav-tabs-border-color: $color-scarpa-flow;
// $nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius: 0.625rem;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $light;
$nav-tabs-link-active-bg: $secondary;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg;

// Forms
$input-focus-border-color: lighten($primary, 25%);

$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-border-color: $primary;

$custom-control-indicator-focus-box-shadow: 0 0 0 $input-btn-focus-width
	$input-btn-focus-color;
$custom-control-indicator-focus-border-color: $input-focus-border-color;
