@import "variables";
@import "mixins";

@each $color, $value in $theme-colors {
	.btn-outline-gray-#{$color} {
		@include button-outline-gray-variant($color-waterloo, $value);
	}
}

@each $color1, $value1 in $theme-colors {
	@each $color2, $value2 in $theme-colors {
		.btn-outline-#{$color1}-#{$color2} {
			@include button-outline-gray-variant($value1, $value2, $light);
		}
	}
}
