@import "assets/scss/helpers/variables";

.test {
	&__list {
		&__cta {
			align-items: center;
			background-color: $color-tuna;
			border: 0.25rem solid $primary;
			border-radius: 0.5rem;
			color: $primary;
			display: flex;
			font-family: $font-family-gotham-black;
			font-size: 2.18rem;
			justify-content: space-between;
			margin-bottom: 1rem;
			padding: 4rem 5rem;
			&::after {
				border-top: 0.25rem solid $secondary;
				border-right: 0.25rem solid $secondary;
				border-radius: 0.25rem;
				content: "";
				height: 2.18rem;
				transform: rotate(45deg);
				width: 2.18rem;
			}
			&:hover {
				color: $primary;
				text-decoration: none;
			}
		}
		&__cta__wrapper {
			@include media-breakpoint-up(lg) {
			column-gap: 1.875rem;
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin-bottom: 1.875rem;
			}
		}
	}
}
