@import "assets/scss/helpers/functions";
@import "assets/scss/helpers/variables";
@import "assets/scss/helpers/fonts";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/utilities/borders";
@import "bootstrap/scss/utilities/text";

@import "assets/scss/helpers/buttons";
@import "assets/scss/utilities/display";

html {
	font-size: 2.5vw;
	@include media-breakpoint-up(sm) {
		font-size: $container-max-width-sm * 0.025;
	}
	@include media-breakpoint-up(md) {
		font-size: $font-size-root;
	}
}

body {
	margin: 0 auto;
	background-color: $color-shark;
	background-image: url("./assets/images/background-pattern.png");
	color: $light;
}

h3,
.h3 {
	color: $primary;
	font-family: $font-family-gotham-black;
}

hr {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

ul {
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

.nav-tabs {
	display: grid;
	column-gap: 15px;
	@include media-breakpoint-up(sm) {
		grid-auto-flow: column;
	}
	.nav-link {
		background-color: $light;
		box-shadow: 0px -1px 2px;
		color: $color-scarpa-flow;
		font-size: 1.125rem;
		text-align: center;
		@include media-breakpoint-up(sm) {
			box-shadow: none;
		}
		&.active {
			@include media-breakpoint-down(sm) {
				order: 1;
			}
		}
	}
}

.tab-content {
	> .tab-pane {
		background-color: $color-scarpa-flow;
		font-size: 0.81rem;
		padding: 30px;
	}
}

.btn {
	text-transform: uppercase;
}

.btn-outline-primary-secondary {
	margin-bottom: 10px;
}