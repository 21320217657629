@mixin button-outline-gray-variant(
	$color,
	$color-hover: color-yiq($color),
	$font-color: $color-hover,
	$active-border: $color-hover
) {
	color: $font-color;
	border-color: $color;

	@include hover() {
		color: $font-color;
		border-color: $active-border;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $color;
		background-color: transparent;
		border-color: $color;
	}
}
