@import 'assets/scss/helpers/functions';
@import "assets/scss/helpers/variables";

.header {
    display: flex;
    
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: space-between;
}

.header .header__pgdate-logo {
    width: fit-content;
    height: 7rem;
}



.header > * {
    display: inline-block;
}

.header__publicis-media-logo {
    width: fit-content;
    height: 7rem;
}

.header__prodigious-logo {
    width: rem(122);
}

.header__divider {
    height: rem(51);
    margin: 0 rem(47) 0 rem(51);
    border-right: 1px solid $white;
}