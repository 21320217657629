@import "assets/scss/helpers/functions";
@import "assets/scss/helpers/variables";

.footer {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 0.3125rem solid $primary;
}

.footer__background-img {
	width: 100%;
	opacity: 0.1;
	z-index: -1;
}

.footer__wrapper {
	padding: rem(44) 0 rem(65);
}

.footer__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: rem(29);
}

.footer__row--no-margin {
	margin-bottom: 0;
}

.footer__logos {
	display: flex;
	align-items: center;
}

.footer__logos > * {
	display: inline-block;
}

.footer__publicis-media-logo {
	width: rem(58);
}

.footer__prodigious-logo {
	width: rem(108);
}

.footer__divider {
	height: rem(36);
	margin: 0 rem(22);
	border-right: 1px solid $white;
}

.footer__second-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer__locations {
	display: flex;
	font-size: 2.5rem;
	line-height: 1;
	@include media-breakpoint-up(sm) {
		font-size: 0.625rem;
	}
}

.footer__location-colombia > p,
.footer__location-costa-rica > p {
	margin-bottom: 0;
	font-family: $font-family-gotham-light;
	font-size: 1.25rem;
	line-height: 1.5;
	@include media-breakpoint-up(sm) {
		font-size: 0.625rem;
	}
}

.footer__social-links {
	display: flex;
	justify-content: space-around;
	@include media-breakpoint-up(sm) {
		justify-content: space-between;
	}
}

.footer__social-links > a > img {
	height: rem(62);
	width: rem(62);
	
	@include media-breakpoint-up(sm) {
		height: rem(31);
		width: rem(31);
	}
}

.footer__social-links > a:last-child {
	margin-right: 0;
}
